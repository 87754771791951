<template>
  <div
    class="login-form position-relative d-flex flex-column align-items-center"
  >
    <div
      class="row w-100 position-absolute px-3 top-0 bg-gray d-sm-none d-block py-4"
    >
     
      <img src="/img/logo-triomarkets.svg" class="col img-fluid logo-mobile" alt="TrioMarkets logo">
    </div>
    <Language class="mt-2 pt-2 mt-sm-0 pt-sm-0" />
    <div class="w-100 px-sm-5 px-4">
      <div
        class="pointer login-logo d-flex align-items-center p-md-4 p-3 d-sm-block d-none justify-content-center"
      >
        <a :href="siteURL" class="d-flex justify-content-center">
       
          <img
            :src="`/img/logo-triomarkets.svg`"
            class="img-fluid"
            alt="TrioMarkets"
          />
        </a>
      </div>
      <h3 class="d-sm-none mt-sm-0 mt-5 pt-sm-0 pt-5 text-center">
        {{ $t("login.clientPortal") }}
      </h3>
      <Form
        class="w-100 d-flex flex-column align-items-center justify-content-center px-sm-0 px-1"
        @submit="loginClient"
        :validation-schema="schema"
      >
        <Field
          name="email"
          v-model="accountData.email"
          v-slot="{ field, meta }"
        >
          <div class="label w-100">
            <small>
              {{ $t("register.steps.step1.accountType.email") }}
              <span class="text-danger">*</span>
            </small>
          </div>
          <input
            v-bind="field"
            :class="{
              'form-control': true,
              'is-invalid': !meta.valid && meta.touched,
            }"
            :placeholder="$t('login.email')"
          />
        </Field>
        <ErrorMessage name="email" class="error" />
        <Field
          name="password"
          v-model="accountData.password"
          v-slot="{ field, meta }"
        >
          <div class="label w-100">
            <small>
              {{ $t("register.steps.step1.accountType.pass") }}
              <span class="text-danger">*</span>
            </small>
          </div>
          <input
            type="password"
            v-bind="field"
            :class="{
              'form-control': true,
              'is-invalid': !meta.valid && meta.touched,
            }"
            :placeholder="$t('login.password')"
          />
        </Field>
        <ErrorMessage name="password" class="error" />
        <router-link
          :to="`/${$route.params.lang}/resetPassword`"
          class="mt-2 text-decoration-none text-purple w-100 text-end"
          >{{ $t("forgotPassword.title") }}</router-link
        >
        <div
          class="buttonset mt-3 d-flex flex-column justify-content-around align-items-center w-75"
        >
          <div
            v-if="response.message"
            :class="[
              'alert',
              response.success ? 'alert-success' : 'alert-danger',
              'w-100 text-center',
            ]"
            role="alert"
          >
            {{ response.message }}
          </div>
          <button
            :disabled="submitted"
            class="btn btn-gradient-purple text-white w-75 mb-0"
          >
            <span v-show="!submitted">{{ $t("login.loginBtn") }}</span>
            <loader :isLoading="submitted" />
          </button>
          <p class="mb-0 mt-3">{{ $t("login.noAccountLink") }}</p>
          <router-link
            :to="`/${$route.params.lang}/register`"
            custom
            v-slot="{ navigate }"
          >
            <span @click="navigate" role="button" class="text-purple">
              {{ $t("login.signUpHere") }}</span
            >
          </router-link>
        </div>
      </Form>
    </div>
    <div class="disclaimer text-justify bg-gray p-4 mt-5">
      <p v-html="$t('disclaimer', { license: license })"></p>
    </div>
  </div>
</template>
<script>
import loader from "./loader";
import Language from "./Language";
import { getters } from "../store";
import { object, string } from "yup";
import { Field, Form, ErrorMessage } from "vee-validate";
export default {
  name: "Login",
  data() {
    return {
      accountData: {
        email: "",
        password: "",
        license: "",
        httpref: window.location.origin,
      },
      submitted: false,
      response: {
        success: false,
        message: "",
      },
    };
  },
  components: {
    loader,
    Form,
    Field,
    Language,
    ErrorMessage,
  },
  computed: {
    license() {
      return getters.license();
    },
    selectedLang() {
      return getters.selectedLang();
    },
    schema() {
      return object({
        email: string()
          .required(this.$t("register.steps.step1.validation.emailRequired"))
          .email(this.$t("register.steps.step1.validation.emailValid")),
        password: string().required(
          this.$t("register.steps.step1.validation.passwordRequired")
        ),
      });
    },
    siteURL() {
      return getters.siteURL();
    },
  },
  watch: {
    license: {
      handler(license) {
        this.accountData.license = license;
      },
      immediate: true,
    },
  },
  methods: {
    async loginClient() {
      this.submitted = true;
      await this.$axios({
        method: "POST",
        url: "/crm/login",
        data: this.accountData,
      })
        .then(({ data }) => {
          this.submitted = false;
          this.response = {
            success: data.success,
            message: data.success ? "Redirecting...." : data.message,
          };
          if (data.success) {
            window.location.replace(data.data[0].url);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
};
</script>
