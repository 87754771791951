<template>
  <div
    class="container-fluid regulation-wrapper position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
  >
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-10 col-12">
          <div class="row popup-container position-relative">
            <div class="col-md-5 popup-left d-flex justify-content-center">
              <img
                src="/img/logo-new.svg"
                alt="TrioMarkets logo"
                class="img-fluid mb-4"
              />
            </div>
            <div class="col-12 text-center pt-4 d-md-none flag-mobile">
              <img
                src="/img/regulation/maur-flag-regulation.png"
                alt="Mauritius flag"
                class="img-fluid"
              />
            </div>
            <div
              class="col-lg-6 offset-lg-1 col-md-11 offset-md-1 popup-right position-relative text-center p-5"
            >
              <h4 class="fw-bold">{{ $t("regulation.dearClient") }}</h4>
              <p
                class="fs-6 text-justify"
                v-html="$t('regulation.regulationText')"
              ></p>
              <p class="fs-5 fw-bold">{{ $t("regulation.goTo") }}</p>
              <a @click="cancel()" class="btn btn-gradient-purple w-50">triomarkets.eu</a>
              <div class="position-absolute flag">
                <img src="/img/regulation/maur.png" class="" alt="mauritius" />
              </div>
            </div>
            <a
              @click="acceptRegulation()"
              class="close position-absolute w-50 d-flex justify-content-end end-0"
            >
              <img src="/img/regulation/close.svg" alt="close" />
            </a>
            <Language id="regulationLang" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Language from "./Language";
import { mutations } from "../store";
export default {
  name: "Regulation",
  methods: {
    acceptRegulation() {
      mutations.setRegulation();
    },
    cancel() {
      window.location.replace("https://triomarkets.eu" + this.$route.path);
    },
  },
  components: {
    Language,
  },
};
</script>
<style scoped lang="scss"></style>
