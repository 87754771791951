<template>
  <div
    class="login-form position-relative d-flex flex-column align-items-center"
  >
    <div
      class="row w-100 position-absolute px-3 top-0 bg-gray d-sm-none d-block py-4"
    >
    <img src="/img/logo-triomarkets.svg" class="col img-fluid logo-mobile" alt="TrioMarkets logo">

    </div>
    <Language class="mt-2 pt-2 mt-sm-0 pt-sm-0" />
    <div class="w-100 px-sm-5 px-4">
      <div class="pointer login-logo text-center p-md-4 p-3 d-sm-block d-none">
        <a :href="siteURL" class="text-center w-100">
          <img
            src="/img/logo-left.svg"
            alt="Logo"
            class="px-2 img-fluid trio"
          />
          <img
            :src="`/img/logo-right.svg`"
            class="img-fluid"
            alt="TrioMarkets"
          />
        </a>
      </div>
      <Form
        class="w-100 d-flex flex-column align-items-center justify-content-center mt-5"
        @submit="sendResetPasswordEmail"
        :validation-schema="emailSchema"
        v-if="!resetPassword_key"
      >
        <Field
          name="email"
          v-model="accountData.email"
          v-slot="{ field, meta }"
        >
          <div class="label w-100">
            <small>
              {{ $t("register.steps.step1.accountType.email") }}
              <span class="text-danger">*</span>
            </small>
          </div>
          <input
            v-bind="field"
            :class="{
              'form-control': true,
              'is-invalid': !meta.valid && meta.touched,
            }"
            :placeholder="$t('login.email')"
          />
        </Field>
        <ErrorMessage name="email" class="error" />
        <router-link
          :to="`/${$route.params.lang}/login`"
          class="mt-3 mb-0 text-decoration-none text-purple"
          >{{ $t("forgotPassword.backToLoginBtn") }}</router-link
        >
        <div class="buttonset w-100">
          <div
            v-if="response.message"
            :class="[
              'alert w-100 text-center mt-2 mb-0',
              response.success ? 'alert-success' : 'alert-danger',
            ]"
            role="alert"
          >
            {{ response.message }}
          </div>
          <div class="text-center">
            <button :disabled="submitted" class="btn btn-gradient-purple mt-3 mb-3 w-50">
              <span v-show="!submitted">
                {{ $t("forgotPassword.resetPasswordBtn") }}</span
              >
              <loader :isLoading="submitted" />
            </button>
          </div>
          <router-link
            :to="`/${$route.params.lang}/register`"
            custom
            v-slot="{ navigate }"
          >
            <span
              @click="navigate"
              role="button"
              class="btn w-100 text-purple"
            >
              {{ $t("register.steps.step1.registerBtn") }}</span
            >
          </router-link>
        </div>
      </Form>
      <Form
        class="w-100 d-flex flex-column align-items-center justify-content-center"
        @submit="resetPassword"
        :validation-schema="passwordSchema"
        v-if="resetPassword_key"
      >
        <Field
          name="password"
          v-model="accountData.password"
          v-slot="{ field, meta }"
        >
          <div class="label w-100">
            <small>
              {{ $t("register.steps.step1.accountType.pass") }}
              <span class="text-danger">*</span>
            </small>
          </div>
          <input
            type="password"
            v-bind="field"
            :class="{
              'form-control': true,
              'is-invalid': !meta.valid && meta.touched,
            }"
            :placeholder="$t('forgotPassword.password')"
          />
        </Field>
        <ErrorMessage name="password" class="error" />
        <Field
          name="repeatPassword"
          v-model="accountData.repeatPassword"
          v-slot="{ field, meta }"
        >
          <div class="label w-100">
            <small>
              {{ $t("register.steps.step1.accountType.rpass") }}
              <span class="text-danger">*</span>
            </small>
          </div>
          <input
            type="password"
            v-bind="field"
            :class="{
              'form-control': true,
              'is-invalid': !meta.valid && meta.touched,
            }"
            :placeholder="$t('forgotPassword.repeatPassword')"
          />
        </Field>
        <ErrorMessage name="repeatPassword" class="error" />
        <router-link
          v-if="resetPassword_key && !response.success"
          :to="`/${$route.params.lang}/login`"
          class="mt-3 mb-0 text-decoration-none text-purple"
          >{{ $t("forgotPassword.backToLoginBtn") }}</router-link
        >
        <div
          class="buttonset mt-3 d-flex flex-column justify-content-around align-items-center w-100"
        >
          <div v-if="response.message" class="text-center">
            <img
              v-if="response.success"
              src="/img/success.png"
              alt="success"
              class="animated zoomIn mt-2 mb-2"
            />
            <div
              :class="[
                'alert w-100 text-center mt-0 mb-2',
                response.success ? 'alert-success' : 'alert-danger',
              ]"
              role="alert"
            >
              {{ response.message }}
            </div>
          </div>
          <button
            v-if="resetPassword_key && !response.success"
            :disabled="submitted"
            class="btn btn-gradient-purple w-50"
          >
            <span v-show="!submitted">{{
              $t("forgotPassword.changePasswordBtn")
            }}</span>
            <loader :isLoading="submitted" />
          </button>
          <router-link
            v-if="resetPassword_key && response.success"
            :to="`/${$route.params.lang}/login`"
            custom
            v-slot="{ navigate }"
          >
            <span @click="navigate" role="button" class="btn btn-gradient-purple w-50">
              {{ $t("login.loginBtn") }}</span
            >
          </router-link>
        </div>
      </Form>
    </div>
    <div class="disclaimer text-justify bg-gray p-4 mt-5">
      <p v-html="$t('disclaimer', { license: license })"></p>
    </div>
  </div>
</template>
<script>
import loader from "./loader";
import Language from "./Language";
import { getters } from "../store";
import { object, string, ref } from "yup";
import { Field, Form, ErrorMessage } from "vee-validate";
export default {
  data() {
    return {
      email: "",
      submitted: false,
      response: {
        success: false,
        message: "",
      },
      accountData: {
        email: "",
        password: "",
        repeatPassword: "",
        license: "",
        httpref: window.location.origin,
        key_name: "",
      },
    };
  },
  components: {
    loader,
    Form,
    Field,
    Language,
    ErrorMessage,
  },
  computed: {
    license() {
      return getters.license();
    },
    emailSchema() {
      return object({
        email: string()
          .required(this.$t("register.steps.step1.validation.emailRequired"))
          .email(this.$t("register.steps.step1.validation.emailValid")),
      });
    },
    passwordSchema() {
      return object({
        password: string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
            this.$t("register.steps.step1.validation.passwordChar")
          )
          .min(8, this.$t("register.steps.step1.validation.passwordLength"))
          .required(
            this.$t("register.steps.step1.validation.passwordRequired")
          ),
        repeatPassword: string().oneOf(
          [ref("password"), null],
          this.$t("register.steps.step1.validation.passwordNotMatch")
        ),
      });
    },
    siteURL() {
      return getters.siteURL();
    },
    resetPassword_key() {
      return this.$route.query.key ?? "";
    },
  },
  watch: {
    license: {
      handler(license) {
        this.accountData.license = license;
      },
      immediate: true,
    },
    resetPassword_key: {
      handler(resetPassword_key) {
        this.accountData.key_name = resetPassword_key;
      },
      immediate: true,
    },
  },
  methods: {
    async sendResetPasswordEmail(values, { resetForm }) {
      this.submitted = true;
      await this.$axios({
        method: "POST",
        url: "/crm/resetPasswordEmail",
        data: this.accountData,
      })
        .then(({ data }) => {
          this.submitted = false;
          this.response = {
            success: data.success,
            message: data.success
              ? this.$t("forgotPassword.emailSent")
              : this.$t("forgotPassword.accountNotFound"),
          };
          if (data.success) {
            resetForm();
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async resetPassword(values, { resetForm }) {
      await this.$axios({
        method: "POST",
        url: "/crm/resetPassword",
        data: this.accountData,
      })
        .then(({ data }) => {
          this.submitted = false;
          this.response = {
            success: data.success,
            message: data.success
              ? this.$t("forgotPassword.resetPasswordSuccess")
              : this.$t("forgotPassword.resetPasswordLinkExpired"),
          };
          if (data.success) {
            resetForm();
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
};
</script>
