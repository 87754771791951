<template>
  <div></div>
</template>
<script>
import Confetti from "vue-confetti/src/confetti.js";
export default {
  data() {
    return {
      confetti: new Confetti(),
    };
  },
  mounted() {
    this.confetti.start({
      defaultColors: ["#FF7373", "#007BFF", "#009D06", "#FFC82B"],
      particles: [
        {
          type: "circle",
        },
      ],
      defaultSize: 7,
    });
    setTimeout(() => {
      this.confetti.stop();
    }, 5000);
  },
  methods: {},
};
</script>