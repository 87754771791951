<template>
  <div class="language-selector position-absolute d-flex flex-column p-1">
    <a @click="showLanguage = !showLanguage" class="mb-2" href="#">
      <img :src="`/img/lang/${this.selectedLanguage}.svg`" alt="Language" />
    </a>
    <transition name="slide-fade">
      <div
        v-if="showLanguage"
        class="language-dropdown d-flex flex-column bg-gray"
      >
        <a
          v-for="(lang, id) in translationLanguages"
          :key="id"
          @click.prevent="changeLanguage(lang.code)"
        >
          <img
            :src="`/img/lang/${lang.code}.svg`"
            class="mb-1"
            :alt="lang.name"
          />
        </a>
      </div>
    </transition>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
export default {
  name: "Language",
  data() {
    return {
      showLanguage: false,
      // host: location.host,
    };
  },
  computed: {
    license() {
      return getters.license();
    },
    languages() {
      return getters.languages();
    },
    translationLanguages() {
      if (this.license === "eu") {
        return this.languages.filter(({ code }) => {
          return (
            code !== this.selectedLanguage &&
            code !== "cn" &&
            code !== "vnm" &&
            code !== "arb" &&
            code !== "my"
          );
        });
      } else {
        return this.languages.filter(({ code }) => {
          return code !== this.selectedLanguage;
        });
      }
    },
    selectedLanguage() {
      return this.$root.$i18n.locale;
    },
  },
  // watch: {
  //   host() {
  //     if (this.host.includes("arb")) {
  //       document
  //         .querySelectorAll(".form-select")
  //         .forEach((e) => e.classList.add("form-inputs-rtl"));
  //         document
  //         .querySelectorAll(".form-control")
  //         .forEach((e) => e.classList.add("form-inputs-rtl"));
  //     }else{
  //       document
  //         .querySelectorAll(".form-select")
  //         .forEach((e) => e.classList.remove("form-inputs-rtl"));
  //         document
  //         .querySelectorAll(".form-control")
  //         .forEach((e) => e.classList.remove("form-inputs-rtl"));
  //     }
  //   },
  // },
  methods: {
    changeLanguage(lang) {
      this.showLanguage = false;
      this.$root.$i18n.locale = lang;
      mutations.changeLanguage(this.$root.$i18n.locale);
      this.$router.replace({
        name: this.$route.name,
        params: { lang: lang },
        query: this.$route.query,
      });
      // if (lang === "arb") {
      //   this.convertFormToRTL();
      // }else{
      //   this.convertFormToLTR();
      // }
      // let routeName = this.$route.path.split(`/${this.$route.params.lang}/`);
      // this.$router.replace(`/${this.$root.$i18n.locale}/${routeName[1]}`);
    },
    //  convertFormToRTL() {
    //   document.querySelectorAll("form-select").forEach((e) => {
    //     e.classList.add("form-inputs-rtl");
    //   });
    // },
    // convertFormToLTR() {
    //   document.querySelectorAll("form-select").forEach((e) => {
    //     e.classList.remove("form-inputs-rtl");
    //   });
    // },
  },
};
</script>
<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
