<template>
  <div
    class="
      verify
      text-center
      py-4
      d-flex
      flex-column
      align-items-center
      justify-content-center
      w-100
    "
  >
    <h3>
      {{ $t("register.steps.step2.title") }}
    </h3>
    <h4 class="fw-bold fs-5">{{ $t("register.steps.step2.subTitle") }}</h4>
    <img
      class="animated zoomInLeft"
      style="max-width: 100px"
      src="/img/letter.svg"
      alt="Email verification"
    />
    <h2 class="text-purple">
      {{ $t("register.steps.step2.oneMoreStep", { name: user.name }) }}
    </h2>
    <h4 class="fw-bold fs-5">{{ $t("register.steps.step2.verifyEmail") }}</h4>
    <div v-if="user.email != null" class="annotation">
      <p class="fs-6">
        Not {{ user.name }}?
        <a class="pointer text-purple" @click.prevent="startAgain">{{
          $t("register.steps.step2.startAgain")
        }}</a>
      </p>
    </div>
    <div class="p-3">
      <p
        v-html="$t('register.steps.step2.receiveEmail', { email: user.email })"
      ></p>
    </div>
    <div class="p-3">
      <p>{{ $t("register.steps.step2.notReceiveEmail") }}</p>
    </div>
    <div v-if="resend === 0" class="p-3">
      <p class="m-0">
        {{ $t("register.steps.step2.resendEmail") }}
        <br />
        <a href="#" @click="resendEmail(1)">{{
          $t("register.steps.step2.resendEmailLink")
        }}</a>
      </p>
      <p class="m-0">
        {{ $t("register.steps.step2.contact", { license: license }) }}
      </p>
    </div>
    <div
      v-if="resend === 1"
      v-html="
        $t('register.steps.step2.emailResendAgain', {
          email: user.email,
          timer: timer,
          seconds: $t('register.steps.step2.seconds'),
        })
      "
      class="p-3"
    ></div>
    <div v-if="resend == 2" class="p-3">
      <p
        v-html="
          $t('register.steps.step2.notConfirmedEmail', {
            email: user.email,
          })
        "
      ></p>
    </div>
    <div class="p-3">
      <h4 v-html="$t('register.steps.step2.trioMarketsTeam')"></h4>
    </div>
    <div class="buttonset w-100 text-center">
      <div
        v-if="response"
        class="alert alert-danger w-100 text-center mt-4 mb-0"
        role="alert"
      >
        {{ response }}
      </div>
      <button
        :disabled="submitted || resend !== 0"
        class="btn btn-gradient-purple w-75"
        @click.prevent="resendEmail(2)"
      >
        <span v-show="!submitted" :disabled="resend !== 0">
          {{
            resend !== 2
              ? $t("register.steps.step2.continuebtn")
              : $t("register.steps.step2.pleaseWait", {
                  timer: timer,
                  seconds: $t("register.steps.step2.seconds"),
                })
          }}
        </span>
        <div
          v-show="submitted"
          class="spinner-border text-white"
          role="status"
        ></div>
      </button>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../../store";
export default {
  data() {
    return {
      resend: 0,
      timer: 30,
      response: "",
      submitted: false,
    };
  },
  computed: {
    license() {
      return getters.license();
    },
    user() {
      return getters.user();
    },
  },
  watch: {
    resend: {
      handler(resend) {
        if (resend > 0) {
          this.countdown();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async resendEmail(type) {
      if (this.resend > 0) {
        this.submitted = true;
        await this.$axios({
          method: "POST",
          url: "/crm/resendEmail",
          data: {
            license: "eu",
            email_to: this.user.email,
            customer_no: this.user.userID,
            httpref: window.location.origin,
          },
        })
          .then(({ data }) => {
            this.submitted = false;
            if (data) {
              if (type <= 2) {
                this.resend = type;
              } else {
                mutations.nextStep(3);
              }
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        mutations.nextStep(3);
      }
    },
    startAgain() {
      mutations.setUser();
      mutations.nextStep(1);
    },
    countdown() {
      let timeleft = 30;
      let downloadTimer = setInterval(() => {
        timeleft--;
        this.timer = timeleft;
        if (this.timer <= 0) {
          this.timer = 30;
          this.resend = 0;
          clearInterval(downloadTimer);
        }
      }, 1000);
    },
  },
};
</script>