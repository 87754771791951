<template>
  <div class="reg-box position-relative w-auto d-flex">
    <div
      class="reg-left position-relative flex-column align-items-center justify-content-center animated fadeL p-4"
    >
      <div class="logo d-flex flex-column align-items-center pt-5 pb-2 px-0">
        <a class="text-center" :href="siteURL">
          <img src="/img/triomarkets-white.png" alt="TrioMarkets Logo" class="mb-2" />
        </a>
      </div>
      <div class="step-box pt-5">
        <div class="step">
          <div
            :class="[
              'number',
              currentStep == 1
                ? 'active-step'
                : currentStep == 2 || currentStep == 3
                ? 'done-step'
                : '',
            ]"
          >
            1
          </div>
          <div class="step-text">
            <p>{{ $t("register.progress.step1.title") }}</p>
            <p class="sub sm">{{ $t("register.progress.step1.subTitle") }}</p>
          </div>
        </div>
        <div class="step">
          <div
            :class="[
              'number',
              currentStep == 2
                ? 'active-step'
                : currentStep == 3
                ? 'done-step'
                : '',
            ]"
          >
            2
          </div>
          <div class="step-text">
            <p>{{ $t("register.progress.step2.title") }}</p>
            <p class="sub sm">{{ $t("register.progress.step2.subTitle") }}</p>
          </div>
        </div>
        <div class="step">
          <div :class="['number', currentStep === 3 ? 'active-step' : '']">
            3
          </div>
          <div class="step-text">
            <p>{{ $t("register.progress.step3.title") }}</p>
            <p class="sub sm">{{ $t("register.progress.step3.subTitle") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="reg-right position-relative d-flex flex-column align-items-center animated fadeR"
    >
      <div class="reg-right-inside pb-0 py-5 px-3">
        <div
          class="row w-100 position-absolute px-3 top-0 bg-gray d-sm-none d-block py-4"
        >
        <img src="/img/logo-triomarkets.svg" class="col img-fluid logo-mobile" alt="TrioMarkets logo">

        </div>
        <Language class="mt-2 pt-2 mt-sm-0 pt-sm-0" />
        <Registration class="mt-sm-0 mt-4" v-if="currentStep === 1" />
        <Verification class="mt-sm-0 mt-4" v-if="currentStep === 2" />
        <Confirmation class="mt-sm-0 mt-4" v-if="currentStep === 3" />
      </div>
      <div class="disclaimer bg-gray p-4">
        <p
          class="text-justify"
          v-html="$t('disclaimer', { license: license })"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import Language from "./Language";
import { getters } from "../store";
import Registration from "./steps/Registration";
import Verification from "./steps/Verification";
import Confirmation from "./steps/Confirmation";
export default {
  name: "Register",
  components: {
    Language,
    Registration,
    Verification,
    Confirmation,
  },
  computed: {
    license() {
      return getters.license();
    },
    currentStep() {
      return getters.currentStep();
    },
    siteURL() {
      return getters.siteURL();
    },
  },
};
</script>
