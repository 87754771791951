<template>
  <span
    v-show="isLoading"
    class="spinner-border spinner-border-sm align-baseline loader ms-2"
    role="status"
    aria-hidden="true"
  ></span>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
  },
};
</script>